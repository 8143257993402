<template>
  <div>
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
          <!-- title -->
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12">
                <h5 class="font-semibold m-0">测试</h5>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: space-between">
                <a-date-picker show-time v-model="twTime"> </a-date-picker>
                <a-button size="small" @click="test">获取tw内容</a-button>
                <a-button size="small" @click="chattest">chat测试</a-button>
              </a-col>
            </a-row>
          </template>
          <!--  -->
          <a-card v-for="(item, index) in testList" :title="'板块：' + item.classifyName" :key="index"
            :headStyle="{ color: '#1890FF' }" style="margin-bottom: 20px;">
            <div v-for="(user, index2) in item.user" :key="index2">
              <h6>作者：{{ user.name }}</h6>
              <p style="white-space: pre-wrap;">{{ user.content }}</p>
            </div>
          </a-card>
        </a-card>
      </a-col>
    </a-row>

  </div>
</template>

<script>
let key = 1;
import { chattestFollow, testFollow, getSummaryDetailsList } from "@/api/service.js";
import axios from "axios";
export default ({
  components: {
  },
  data() {
    return {
      testList: [{
        user: []
      }],
      twTime: ''
    }
  },
  mounted() {
    this.getSummaryDetailsList();
  },
  methods: {
    getSummaryDetailsList() {
      getSummaryDetailsList().then(res => {
        let data = res.data.data;
        let list = [];
        let obj = {}
        data.forEach(item => {
          if (!obj[item.classifyName]) {
            obj[item.classifyName] = {};
          }
          if (!obj[item.classifyName][item.userName]) {
            obj[item.classifyName][item.userName] = [];
          }
          obj[item.classifyName][item.userName].push(item.content);
        });
        for (const key in obj) {
          let classifyObj = {
            classifyName: key,
            time: obj[key].time,
            user: []
          }
          for (const user in obj[key]) {
            let contentArr = obj[key][user];
            let content = ''
            contentArr.forEach((item, index) => {
              item = item.replace(/https:\/\/t\.co\/\S+/g, '');
              content += `内容${index + 1}：${item}\n`;
            })
            classifyObj.user.push({
              name: user,
              content
            })
          }
          list.push(classifyObj);
        }
        this.testList = list;
      })
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async chattest() {
            chattestFollow()
return
      let text = `作者：LaserCat397.eth 
    作者文章：推荐大家优先使用以下三个平台交易天方 @TinFunNFT  
    OKX NFT:  
    Element :  
    X2Y2:  
    
    作者：JAMES 
    作者文章：Frontrun the news
    
    $ens $op  
    `
      const request = axios.create({
        timeout: 60 * 1000 // 请求超时时间
      })
      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: "gpt-3.5-turbo",
        messages: [{
          "role": "system",
          "content": `输入的文段是区块链中文Alpha hunter在最近一个时段在推特上的发言。Alpha指的是区块链中还在发展初期的项目，一般没有很多投资者或者用户，兼具极高的潜在回报和风险，Alpha hunter指的是有能力发掘此类早期项目的玩家。

            请分析文段，重点关注Alpha hunter所提及的区块链相关的热点概念、项目、币种、关键价位、和未来展望，并判断交易员对其发表文段所包含的情绪及投入仓位。对于多次出现的词汇，需进行重点提示。
            
            以中文为主，币种或区块链专有名词等必要的英文词语为辅，提及的时间需转换为北京时间，以不同Alpha hunter为分段输出信息。
            
            如果出现多个Alpha hunter同时提及同一个概念或币种，在文段最后作总结；如果没有出现上述情况，则不需要在输出文段最后作总结。文段内容如下（文档格式为作者与作者发表的文章内容）：
            `
        },
        {
          "role": "user",
          "content": text
        }]
      }, {
        headers: {
          'Authorization': `Bearer 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`,
          'Content-Type': 'application/json'
        }
      });
      response
      debugger

    },
    async test() {

      // chattestFollow();
      if (!this.twTime) {
        this.$message.warning({ content: '请先选择日期', duration: 2 });
        return
      }
      this.$message.loading({ content: '正在获取中，请耐心等待...', key });
      let time = this.formatDate(this.twTime._d)
      testFollow({
        time
      }).then(res => {
        this.$message.success({ content: '获取成功!', key, duration: 2 });

        this.getSummaryDetailsList();
      })
    }
  }
})

</script>

<style lang="scss"></style>